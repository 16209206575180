<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper company-detail-drawer">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Business Hours</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						v-on:click="updateCompanyDetails"
						class="blue darken-4"
						>Save</v-btn
					>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500 mt-0">Business Hours</label>
						</div>
						<v-radio-group v-model="company_details.business_hours_type" hide-details class="ma-0">
							<v-radio label="24 Hours X 7 day" :value="1"></v-radio>
							<v-radio label="24 Hours X 5 day" :value="2"></v-radio>
							<v-radio label="Custom Hours" :value="3"></v-radio>
						</v-radio-group>
					</div>
					<div class="mb-5 d-flex mt-3">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Week starts on</label>
						</div>
						<v-autocomplete
							:items="weekList"
							depressed
							hide-details
							outlined
							v-model="company_details.weak_start"
						></v-autocomplete>
					</div>
					<div class="mb-5 d-flex" v-if="company_details.business_hours_type == 3">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500 mt-0">Business Timing</label>
						</div>
						<v-radio-group v-model="company_details.timing_type" hide-details class="ma-0">
							<div class="d-flex">
								<v-radio label="Same hours every day" :value="1" class="mb-3"></v-radio>
								<div class="d-flex align-items-center" v-if="company_details.timing_type == 1">
									<div class="filterTagSelect ml-3">
										<template
											v-if="
												company_details && company_details.business_hours && company_details.business_hours[0]
											"
										>
											<TimePicker
												v-on:update:time-picker="updateTime('same_start_time', null, $event)"
												:defaultTime="company_details.business_hours[0].start_time"
												placeholder="End Time"
												width-class="w-85"
											></TimePicker>
										</template>
									</div>
									<div class="pl-2">to</div>
									<div class="filterTagSelect ml-3">
										<template
											v-if="
												company_details && company_details.business_hours && company_details.business_hours[0]
											"
										>
											<TimePicker
												v-on:update:time-picker="updateTime('same_end_time', null, $event)"
												:defaultTime="company_details.business_hours[0].end_time"
												placeholder="End Time"
												width-class="w-85"
											></TimePicker>
										</template>
									</div>
								</div>
							</div>
							<div>
								<v-radio label="Different hours every day" :value="2"></v-radio>
							</div>
						</v-radio-group>
					</div>
					<div
						class="mb-5 d-flex"
						v-if="company_details.business_hours_type == 2 || company_details.business_hours_type == 3"
					>
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500 mt-0">Business Days</label>
						</div>
						<div>
							<div class="d-flex" v-for="(data, index) in company_details.business_hours" :key="index">
								<div class="mw-130">
									<v-checkbox
										v-model="data.status"
										:label="data.title"
										hide-details
										class="mt-0 mb-2"
									></v-checkbox>
								</div>
								<div
									class="d-flex align-items-center"
									v-if="company_details.timing_type == 2 && company_details.business_hours_type == 3"
								>
									<TimePicker
										v-on:update:time-picker="updateTime('start_time', index, $event)"
										:defaultTime="data.start_time"
										placeholder="End Time"
										width-class="w-85"
									></TimePicker>
									<div class="pl-2 pr-2">to</div>
									<TimePicker
										v-on:update:time-picker="updateTime('end_time', index, $event)"
										:defaultTime="data.end_time"
										placeholder="End Time"
										width-class="w-85"
									></TimePicker>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { cloneDeep } from "lodash";
import { UpdateCompanyDetails } from "@/core/lib/setting.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import TimePicker from "@/view/components/TimePickerNew";
export default {
	name: "LocalInformation",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		details: {
			type: Object,
			default: new Object(),
		},
	},
	data() {
		return {
			company_details: {
				business_hours_type: 1,
				weak_start: null,
				timing_type: 1,
			},
			business_time: "same_hours_every_day",
			business_start_time: "12:00 AM",
			business_end_time: "12:00 AM",
			business_hour: "24 Hours X 7 days",
			week_start: "Monday",
			weekList: ["Monday", "Tuesday", "Wednessday", "Thrusday", "Friday", "Saturday", "Sunday"],
			timeList: ["12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM"],
		};
	},
	watch: {
		details: {
			deep: true,
			immediate: true,
			handler(param) {
				this.company_details = cloneDeep(param);
			},
		},
	},
	methods: {
		updateCompanyDetails() {
			UpdateCompanyDetails(this.company_details)
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company details has been updated." },
					]);
					this.$emit("success", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateTime(col, index, data) {
			if (index && index >= 0) {
				this.company_details[index][col] = data;
			} else {
				this.company_details[col] = data;
			}
		},
	},
	components: {
		TimePicker,
	},
};
</script>
<style scoped></style>
